import React from 'react';
import { mapModifiers } from 'libs/component';
import Lottie from 'lottie-react';
import loadingLottieFile from 'lotties/loader.json';

export interface PageLoadingProps {
  animated?: boolean;
  progress?: number | undefined;
}

export const PageLoading: React.FC<PageLoadingProps> = ({ animated = true, progress }) => (
  <div className={mapModifiers('a-page-loading')}>
    {!animated && (
      <picture className="a-page-loading__image">
        <source srcSet="/images/loader-logo.webp" type="image/webp" />
        <source srcSet="/images/loader-logo.png" type="image/png" />
        <img
          data-sizes="auto"
          className="lazyload"
          data-src="/images/loader-logo.png"
          alt="Loading"
          width="74"
          height="186"
        />
      </picture>
    )}
    {animated && (
      <div className="a-page-loading__loader">
        <Lottie animationData={loadingLottieFile} />
      </div>
    )}
    {Number(progress) > 0 && <div className="a-page-loading__progress">{progress}%</div>}
  </div>
);
